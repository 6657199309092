import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { LoginDataProvider } from "./context/LoginDataProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
//console.warn = () => {};
root.render(
  <LoginDataProvider>
    <App />
  </LoginDataProvider>
);
