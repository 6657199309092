import { Form } from "react-bootstrap";
import useFormData, { handleFormChange } from "../hooks/useFormData";
import CustomFormGroup from "../components/CustomFormGroup";
import CustomForm from "../components/CustomForm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../components/AnnouncementDetail.css";
import "./NewAnnouncement.css";
import RadioGroup from "../components/RadioGroup";
import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { LoginDataContext } from "../context/LoginDataProvider";
import { useMemo } from "react";

export default function NewAnnouncement() {
  const { loginData } = useContext(LoginDataContext);
  const quillRef = useRef(null);
  const quillModules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["link", "image"],
        ],
        handlers: {
          image: async () => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();

            input.onchange = async () => {
              const file = input.files[0];
              const formData = new FormData();
              formData.append("image", file);
              const response = await axios({
                method: "post",
                url: process.env.REACT_APP_API_LOC + "/uploads",
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + loginData.token,
                },
              });
              if (response && response.data && response.data.imageUrl) {
                const quillEditor = quillRef.current.getEditor();
                const range = quillEditor.getSelection();
                const index = range ? range.index : quillEditor.getLength();
                quillEditor.insertEmbed(
                  index,
                  "image",
                  process.env.REACT_APP_API_LOC + response.data.imageUrl
                );
              }
            };
          },
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const quillFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "size",
    "video",
    "align",
    "background",
    "direction",
    "code-block",
    "code",
  ];

  const [formData, setFormData] = useFormData({
    title: "",
    content: "",
    type: "",
    isPublic: false,
  });

  const id = new URLSearchParams(useLocation().search).get("id");

  useEffect(() => {
    if (formData.type !== "g" && formData.isPublic) {
      setFormData({
        ...formData,
        isPublic: false,
      });
    }
  }, [formData, setFormData]);

  return (
    <div className="d-flex align-items-center flex-column new-announcement">
      <h1>Crear Nuevo anuncio</h1>
      <CustomForm
        formState={[formData, setFormData]}
        endpoint={
          id
            ? "/announcement/update-announcement/" + id
            : "/announcement/new-announcement"
        }
        entity="anuncio"
        className="w-100"
        detailEndpoint={
          id ? "/announcement/get-announcement-detail/" + id : null
        }
      >
        <CustomFormGroup label="Título" type="text" name="title" />
        <CustomFormGroup
          label="Tipo de noticia"
          type="radio"
          name="type"
          controlOverride={
            <RadioGroup>
              <Form.Check type="radio" label="Genérica" name="type" value="g" />
              <Form.Check
                type="radio"
                label="Actividad"
                name="type"
                value="a"
              />
              <Form.Check
                type="radio"
                label="Concurso/Sorteo"
                name="type"
                value="cs"
              />
            </RadioGroup>
          }
        />

        {formData.type === "g" && (
          <CustomFormGroup
            label="Noticia pública"
            name="isPublic"
            checkable={true}
            controlOverride={
              <Form.Check
                type="switch"
                label="Noticia pública"
                name="isPublic"
                onClick={handleFormChange}
              />
            }
          />
        )}
        <CustomFormGroup
          label="Contenido"
          name="html"
          controlOverride={
            <ReactQuill
              theme="snow"
              className="announcement-detail w-100"
              ref={quillRef}
              modules={quillModules}
              formats={quillFormats}
              style={{ width: "100vw" }}
            />
          }
        />
      </CustomForm>
    </div>
  );
}
