import AnnouncementLinkCard from "../components/AnnouncementLinkCard";

export default function AnnouncementLinks() {
  return (
    <div className="container d-flex justify-content-center w-100 h-100">
      <div className="justify-content-center align-items-center d-flex flex-wrap align-self-center justify-self-center h-100 row">
        <AnnouncementLinkCard
          link="/noticias"
          title="Noticias"
          className="col-md-12"
        />
        <AnnouncementLinkCard
          link="/actividades"
          title="Actividades"
          className="col-md-6"
        />
        <AnnouncementLinkCard
          link="/concursos-y-sorteos"
          title="Concursos y sorteos"
          className="col-md-6"
        />
        {/* <AnnouncementLinkCard
          link="/merchandising"
          title="Merchandising"
          className="col-md-6 proximamente"
        /> */}
        <AnnouncementLinkCard
          link="/movilidad"
          title="Movilidad"
          className="col-md-12 proximamente"
        />
      </div>
    </div>
  );
}
