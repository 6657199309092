export default function SideBanner({ children }) {
  return (
    <>
      <div className="col-2 p-0 d-none d-md-block">
        <div className="side-banner side-banner-left">
          <img
            src="/img/logo.svg"
            title="Logo de la ASEF"
            alt="Logo de la ASEF"
            className="img-fluid"
          />
        </div>
      </div>
      {children}
      <div className="col-2 p-0 d-none d-md-block">
        <div className="side-banner side-banner-right">
          <img
            src="/img/logo.svg"
            title="Logo de la ASEF"
            alt="Logo de la ASEF"
            className="img-fluid"
          />
        </div>
      </div>
    </>
  );
}
