import { useState } from "react";
import "./Pagination.css";

export default function Pagination({
  children,
  isLastPage,
  onPageChange,
  className,
}) {
  const [page, setPage] = useState(1);
  return (
    <>
      {children}
      <div className={"d-flex pagination " + className}>
        <div className="d-flex flex-column justify-content-center">
          <button
            onClick={() => {
              onPageChange(page - 1);
              setPage(page - 1);
            }}
            className="btn btn-primary"
            disabled={page <= 1}
          >
            Página anterior
          </button>
        </div>
        <div className="d-flex flex-column align-items-center">
          <button
            onClick={() => {
              onPageChange(1);
              setPage(1);
            }}
            className="btn btn-primary m-md-5"
            disabled={page <= 1}
          >
            Ir a la primera página
          </button>
          <span>{page}</span>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <button
            onClick={() => {
              onPageChange(page + 1);
              setPage(page + 1);
            }}
            className="btn btn-primary"
            disabled={isLastPage}
          >
            Siguiente página
          </button>
        </div>
      </div>
    </>
  );
}
