import { useEffect, useState } from "react";

let state;

export default function useFormData(initialFormData) {
    state = useState(initialFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {}, [state]);
    return state;
}

export const handleFormChange = (e) => {
    const [formData, setFormData] = state;
    let name, value, checked;
    if (e.target) {
        name = e.target.name;
        value = e.target.value;
        checked = e.target.checked;
    }

    setFormData({
        ...formData,
        [name ? name : 'html']: name ? (checked || value === 'true' ? checked : value) : e,
    });
};