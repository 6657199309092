import "./NewActivity.css";
import CustomForm from "../components/CustomForm";
import CustomFormGroup from "../components/CustomFormGroup";
import useFormData from "../hooks/useFormData";

export default function NewActivity() {
  const [formData, setFormData] = useFormData({
    name: "",
    startDate: "",
    endDate: "",
  });

  return (
    <div className="d-flex align-items-center flex-column justify-content-center new-activity">
      <h1>Crear Nueva Actividad</h1>
      <CustomForm
        formState={[formData, setFormData]}
        endpoint="/activity/new-activity"
        entity="actividad"
        className="w-50"
      >
        <CustomFormGroup
          label="Nombre"
          name="name"
          type="text"
          required
          min={new Date().toISOString().split("T")[0]}
        />
        <CustomFormGroup
          label="Fecha de inicio:"
          name="startDate"
          type="date"
          required
          min={new Date().toISOString().split("T")[0]}
        />
        <CustomFormGroup
          label="Fecha de Fin:"
          name="endDate"
          type="date"
          required
          min={
            formData.startDate
              ? new Date(
                  new Date(formData.startDate).setDate(
                    new Date(formData.startDate).getDate() + 1
                  )
                )
                  .toISOString()
                  .split("T")[0]
              : new Date().toISOString().split("T")[0]
          }
        />
      </CustomForm>
    </div>
  );
}
