import DOMPurify from "dompurify";
import useContentFromBackend from "../hooks/useContentFromBackend";
import { useContext } from "react";
import { LoginDataContext } from "../context/LoginDataProvider";
import { useLocation } from "react-router-dom";
import "./AnnouncementDetail.css";
import "react-quill/dist/quill.snow.css";
import SideBanner from "./SideBanner";
import { useEffect } from "react";

export default function AnnouncementDetail() {
  const { loginData } = useContext(LoginDataContext);
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const { content } = useContentFromBackend(
    loginData
      ? "/announcement/get-announcement-by-id"
      : "/announcement/news/" + id,
    loginData ? "post" : "get",
    "html",
    loginData
      ? {
          id,
        }
      : null,
    loginData ? loginData.token : null
  );

  useEffect(() => {
    document.title =
      "ASEF - " +
      decodeURIComponent(location.pathname.split("/")[2].replaceAll("-", " "));

    return () => {
      document.title =
        "ASEF | Asociación Salmantina de Estudiantes de Farmacia";
    };
  }, [location.pathname]);

  return (
    <div className="announcement-detail row">
      <SideBanner>
        <div
          className="col-12 col-md-8 content ql-editor"
          dangerouslySetInnerHTML={{ __html: DOMPurify().sanitize(content) }}
        ></div>
      </SideBanner>
    </div>
  );
}
