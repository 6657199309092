import UserModal from "./UserModal";
import { LoginDataContext } from "../context/LoginDataProvider";
import ScrollToTopButton from "./ScrollToTopButton";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
import { useContext, useEffect } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import useWindowSize from "../hooks/useWindowSize";

export default function NavBar() {
  const { loginData } = useContext(LoginDataContext);
  const { isMobile } = useWindowSize();

  const location = useLocation();

  let lastScrollPosition = window.scrollY;
  const handleScroll = () => {
    const navClasses = document.querySelector("nav").classList;
    const scrollToTopButtonClasses = document.querySelector(
      ".scroll-to-top-button"
    ).classList;

    if (window.scrollY === 0) {
      navClasses.remove("nav-disappear");
      navClasses.add("nav-at-top");
      navClasses.remove("navbar-dark");
      scrollToTopButtonClasses.add("d-none");
      document.querySelector(".nav-logo img").src = "/img/Logo-fondo.svg";
      lastScrollPosition = window.scrollY;
      return;
    }

    scrollToTopButtonClasses.remove("d-none");
    scrollToTopButtonClasses.add("d-block");

    if (window.scrollY > lastScrollPosition) {
      document
        .querySelectorAll("nav .show")
        .forEach((el) => el.classList.remove("show"));
      navClasses.add("nav-disappear");
      lastScrollPosition = window.scrollY;
      return;
    }

    navClasses.add("nav-appear");
    navClasses.add("navbar-dark");
    navClasses.remove("nav-disappear");
    navClasses.remove("nav-at-top");
    document.querySelector(".nav-logo img").src = "/img/logo-white.svg";

    lastScrollPosition = window.scrollY;
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    lastScrollPosition = window.scrollY;
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  useEffect(() => {
    const handleLinkClick = () => {
      document
        .querySelectorAll("nav .show")
        .forEach((el) => el.classList.remove("show"));
    };

    document
      .querySelectorAll("nav a")
      .forEach((a) => a.addEventListener("click", handleLinkClick));
    document
      .querySelectorAll(".nav-dropdown a")
      .forEach((a) => a.removeEventListener("click", handleLinkClick));
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  window.addEventListener("scroll", handleScroll);
  return (
    <>
      <Navbar expand="md" bg="light" fixed="top">
        <div className="container-fluid">
          <Link className="navbar-brand nav-logo" to="/#">
            <img
              src="/img/logo-white.svg"
              title="Logo de la ASEF"
              alt="Logo de la ASEF"
            />
          </Link>
          <Navbar.Toggle
            aria-controls="nav-content"
            aria-label="Toggle navigation"
            className="my-sm-4"
          />
          <Navbar.Collapse
            id="nav-content"
            className={
              isMobile
                ? "justify-content-center mobile-nav my-4"
                : "justify-content-center"
            }
          >
            <Nav className="">
              {loginData ? (
                <Nav.Item>
                  <Nav.Link href="/#anuncios">Anuncios</Nav.Link>
                </Nav.Item>
              ) : (
                <></>
              )}
              <Nav.Item>
                <Nav.Link href="/#quienes-somos">Quiénes somos</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/#colaboradores">Colaboradores</Nav.Link>
              </Nav.Item>
              {!loginData && (
                <Nav.Item>
                  <Nav.Link href="/#blog">Blog</Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link href="/#como-hacerme-socio">
                  Cómo hacerme socio
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/#contacto">Contacto</Nav.Link>
              </Nav.Item>
              {loginData && loginData.isAdmin && (
                <Nav.Item>
                  <NavDropdown
                    title="Admin"
                    id="admin-dropdown"
                    className="nav-dropdown"
                  >
                    <NavDropdown.Item href="/admin/actividades">
                      Actividades
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/admin/anuncios">
                      Anuncios
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/admin/socios">
                      Socios
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id="navbarNav" className="justify-content-end">
            <Nav>
              <Nav.Item>
                <UserModal />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <ScrollToTopButton />
    </>
  );
}
