import axios from "axios";
import readXlsxFile from "read-excel-file";
import { LoginDataContext } from "../context/LoginDataProvider";
import { useContext, useState } from "react";

export default function AddUsers() {
  const { loginData } = useContext(LoginDataContext);
  const [file, setFile] = useState(null);

  return (
    <>
      <h1 className="mt-5 pt-5">Añadir Socios</h1>
      <p>
        Sube el excel aquí. Se añadirán solo los socios que no estén ya dados de
        alta.
      </p>
      <div className="w-100 mb-3">
        <a href="/excelPlantilla.xlsx" title="Descargar excel plantilla">
          Descargar plantilla
        </a>
      </div>
      <input
        type="file"
        accept=".csv,.xls,.xlsx"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <button
        className="btn btn-primary"
        onClick={() => {
          const mapExcelToModel = (row) => ({
            email: row[5],
            name: row[2],
            surnames: row[3],
            activities: [],
            isAdmin: row[13] === "SÍ",
            endDate: null,
            tokenDate: null,
            grade: row[4],
            phone: row[6],
          });

          readXlsxFile(file).then((rows) => {
            const data = rows.slice(1).map(mapExcelToModel);
            axios
              .post(process.env.REACT_APP_API_LOC + "/user/add-users", data, {
                headers: { Authorization: "Bearer " + loginData.token },
              })
              .then(() => {
                window.location.href = ".";
              });
          });
        }}
      >
        Subir
      </button>
    </>
  );
}
