import axios from "axios";
import React from "react";
import { useContext } from "react";
import { LoginDataContext } from "../context/LoginDataProvider";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { useEffect } from "react";

export default function CustomForm({
  children,
  endpoint,
  formState,
  className,
  detailEndpoint,
  afterSubmit,
}) {
  let { loginData } = useContext(LoginDataContext);
  const [formData, setFormData] = formState;

  if (!loginData) {
    loginData = {
      token: "",
    };
  }

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const config = {
          method: "GET",
          url: process.env.REACT_APP_API_LOC + detailEndpoint,
          headers: { Authorization: "Bearer " + loginData.token },
        };
        const res = await axios(config);
        setFormData(res.data);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "¡Error!",
          text: "Algo ha salido mal, es posible que los componentes de la página no se muestren correctamente",
        });
      }
    };
    if (detailEndpoint) {
      fetchDetail();
    }
  }, [detailEndpoint, loginData.token, setFormData]);

  children = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { formData });
    }
    return child;
  });

  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();

        try {
          await axios.post(process.env.REACT_APP_API_LOC + endpoint, formData, {
            headers: loginData
              ? { Authorization: "Bearer " + loginData.token }
              : null,
          });

          if (afterSubmit) {
            afterSubmit();
          } else {
            window.location.href = ".";
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "¡Error!",
            text: "Algo ha salido mal: " + err,
            willClose: () => {
              setFormData({});
            },
          });
        }
      }}
      className={"d-flex flex-column m-5 " + className}
    >
      {children}
      <Button
        variant="primary"
        type="submit"
        className="btn-send nav-button mt-5"
      >
        Enviar
      </Button>
    </Form>
  );
}
