import React from "react";

export default function RadioGroup({ value, onChange, children }) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange({ target: { name: event.target.name, value: newValue } });
    }
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          checked: value === child.props.value,
          onChange: handleChange,
        })
      )}
    </>
  );
}
