import Swal from "sweetalert2";
import CustomForm from "../components/CustomForm";
import CustomFormGroup from "../components/CustomFormGroup";
import useFormData from "../hooks/useFormData";
import "./Contact.css";

export default function Contact() {
  const [formData, setFormData] = useFormData({
    name: "",
    email: "",
    message: "",
  });

  return (
    <div className="row contact d-flex align-items-center justify-content-center w-100 h-100 contact align-items-center">
      <div className="d-flex w-100 align-items-center justify-content-center mb-lg-2 mb-2 col-12 col-lg-7 form-container px-md-5">
        <CustomForm
          formState={[formData, setFormData]}
          endpoint="/contact/contact"
          className="h-100 row w-100 p-5"
          afterSubmit={() => {
            setFormData({
              name: "",
              email: "",
              message: "",
            });
            Swal.fire({
              icon: "success",
              title: "¡Enviado!",
              text: "Tu mensaje ha sido enviado correctamente. Te responderemos lo antes posible",
            });
          }}
        >
          <CustomFormGroup
            label="Nombre"
            name="name"
            type="text"
            className="col-4 my-2"
            required
          />
          <CustomFormGroup
            label="Email"
            name="email"
            type="text"
            className="col-8 my-2"
            required
          />
          <CustomFormGroup
            label="Mensaje"
            name="message"
            controlOverride={
              <textarea
                name="message"
                className="col-12 mb-3 mb-md-5 required"
              ></textarea>
            }
            required
          />
        </CustomForm>
      </div>
      <div
        className={
          "col-12 col-lg-4 iframe-container d-flex align-items-center justify-content-center mt-2 w-100 mb-lg-3"
        }
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.7530665458344!2d-5.6804721239616445!3d40.96498807135664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f2637522ab9ff%3A0xbe1b18ed7a4e64fc!2sAsociaci%C3%B3n%20Salmantina%20Estudiantes%20Farmacia!5e0!3m2!1ses!2ses!4v1700853324174!5m2!1ses!2ses"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="Ubicación de la asociación"
          aria-hidden="false"
          className={"w-100 h-100"}
        ></iframe>
      </div>
    </div>
  );
}
