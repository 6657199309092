import "./AboutUs.css";

export default function AboutUs() {
  return (
    <>
      <div className="about-container d-flex flex-column justify-content-center align-items-center">
        <div className="text d-flex flex-column about w-100 align-self-center mb-3 mb-md-5">
          <div className="background-rect p-2 p-md-4 w-100 px-3">
            <p>
              Somos un <strong>grupo de estudiantes activos</strong> que
              deseamos ampliar nuestra formación y desarrollar nuevas
              habilidades, así como involucrarnos más activamente en{" "}
              <strong>actividades universitarias y científicas.</strong>
            </p>
            <p>
              Nuestro lema es{" "}
              <strong>estudiantes comprometidos y con principios.</strong>
            </p>
            <p>
              También somos una de las muchas asociaciones que forman parte de{" "}
              <a
                href="https://www.feef.es/"
                title="Enlace a la página de la FEEF"
                target="blank"
              >
                la FEEF
              </a>{" "}
              y los{" "}
              <strong>
                representantes de los estudiantes de farmacia de la USAL a nivel
                nacional.
              </strong>
            </p>{" "}
            <p>
              Además de nuestra función de representación, fomentamos la
              relación entre estudiantes de diversos cursos, organizamos un{" "}
              <strong>congreso anual</strong> y{" "}
              <strong>varias actividades más a lo largo del curso.</strong>
            </p>
          </div>
        </div>
        <div
          className={
            "d-flex flex-column how-to-be-partner-link pb-3 px-5 mt-0 mt-md-5 pb-md-5 background-rect align-items-center"
          }
        >
          <p>¿Todavía no eres socio?</p>
          <a
            className="btn btn-primary nav-button w-100"
            href="/#como-hacerme-socio"
            title="Enlace a beneficios de socio"
          >
            Ver los beneficios
          </a>
        </div>
      </div>
    </>
  );
}
