import { useContext, useState } from "react";
import { LoginDataContext } from "../context/LoginDataProvider";
import { Link, useNavigate } from "react-router-dom";
import useContentFromBackend from "../hooks/useContentFromBackend";
import { Card } from "react-bootstrap";
import "./Announcements.css";
import Pagination from "../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function Announcements({ header, type }) {
  const [page, setPage] = useState(1);
  const [renderFlag, setRenderFlag] = useState(false);
  const { loginData } = useContext(LoginDataContext);
  const navigate = useNavigate();
  const { content, isLastPage } = useContentFromBackend(
    loginData
      ? "/announcement/get-announcement-titles-by-type"
      : "/announcement/news?page=" + page,
    loginData ? "post" : "get",
    "title",
    {
      type: type,
      page,
    },
    loginData ? loginData.token : null,
    true,
    renderFlag
  );

  const result = content.map((announcement) => {
    return (
      <>
        <Card key={announcement[0]} className="mb-3 crd w-75 announcement-card">
          <Card.Title className="crd-title">{announcement[1]}</Card.Title>
          <Card.Body className="crd-body">
            <Link
              key={announcement[0]}
              to={
                (window.location.pathname === "/" ? "./noticias/" : "./") +
                encodeURIComponent(
                  announcement[1]
                    .toLowerCase()
                    .replaceAll(" ", "-")
                    .replaceAll("á", "a")
                    .replaceAll("é", "e")
                    .replaceAll("í", "i")
                    .replaceAll("ó", "o")
                    .replaceAll("ú", "u")
                ) +
                "?id=" +
                announcement[0]
              }
              className="btn btn-primary"
            >
              ver
            </Link>
          </Card.Body>
        </Card>
      </>
    );
  });

  return (
    <div className="w-100 h-100 d-flex flex-column">
      {header && (
        <div className="d-flex flex-row announcements-header align-items-center justify-content-center">
          <Link
            to={window.location.origin}
            title="Volver a la página anterior"
            className="arrow-back"
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="back-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
          </Link>
          <h1 className="mt-5 p-5">{header}</h1>
        </div>
      )}
      <Pagination
        isLastPage={isLastPage}
        onPageChange={(page) => {
          setPage(page);
          setRenderFlag(!renderFlag);
        }}
        className="justify-content-center w-100 mb-5"
      >
        <div className="d-flex flex-column h-75 justify-content-center align-items-center">
          {result}
        </div>
      </Pagination>
    </div>
  );
}
