import { Button, Modal, Form, Alert } from "react-bootstrap";
import React, { useContext, useState } from "react";
import axios from "axios";
import { LoginDataContext } from "../context/LoginDataProvider";
import QRCode from "qrcode.react";
import "./UserModal.css";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faThumbsUp } from "@fortawesome/free-solid-svg-icons";

export default function UserModal() {
  const [formData, setFormData] = useState({
    email: "",
    pwd: "",
    repeatPwd: "",
  });

  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const location = useLocation();
  const [token, setToken] = useState(
    new URLSearchParams(location.search).get("token")
  );
  const [pwdChanged, setPwdChanged] = useState(
    new URLSearchParams(location.search).get("pwd-changed")
  );

  const [showModal, setShowModal] = useState(token || pwdChanged);
  const [requestEmail, setRequestEmail] = useState(false);
  const [error, setError] = useState("");
  const [info, setInfo] = useState(
    pwdChanged ? "Tu contraseña ha sido modificada correctamente" : ""
  );
  const [shortToken, setShortToken] = useState();
  const [shortTokenCopied, setShortTokenCopied] = useState(false);

  const { loginData, setLoginData, clearLoginData } =
    useContext(LoginDataContext);

  const handleCloseModal = () => {
    setShowModal(false);
    setInfo("");
    setError("");
    setToken(null);
    setPwdChanged(null);
    setRequestEmail(false);
    setShortToken(null);
  };

  let modalTitle = "Cambia tu contraseña";
  if (!token) {
    modalTitle = loginData ? "Mi perfil" : "Iniciar sesión";
  }

  return (
    <>
      <Button onClick={() => setShowModal(true)} className="nav-button">
        {loginData ? "Mi perfil" : "Iniciar sesión"}
      </Button>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className={"d-flex justify-content-center align-items-center"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {(token && (
            <Form
              onSubmit={async (e) => {
                e.preventDefault();

                try {
                  await axios.post(
                    process.env.REACT_APP_API_LOC + "/user/change-password",
                    {
                      email: formData.email,
                      newPwd: formData.pwd,
                      token,
                    }
                  );
                  window.location.href = "/?pwd-changed=OK";
                } catch (err) {
                  setError(
                    "Error. Vuelve a intentarlo más tarde o solicita de nuevo el restablecimiento."
                  );
                  setInfo("");
                }
              }}
              className="login-form"
            >
              {error && <Alert variant="danger">{error}</Alert>}
              {info && <Alert variant="info">{info}</Alert>}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Tu email"
                  value={formData.email}
                  onChange={handleFormChange}
                  name="email"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Nueva contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Tu contraseña"
                  value={formData.pwd}
                  name="pwd"
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicRepeatPassword">
                <Form.Label>Repetir contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Tu contraseña"
                  value={formData.repeatPwd}
                  name="repeatPwd"
                  onChange={handleFormChange}
                  isInvalid={
                    formData.pwd &&
                    formData.repeatPwd &&
                    formData.pwd !== formData.repeatPwd
                  }
                  required
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                className="nav-button btn-send"
              >
                Cambiar contraseña
              </Button>
            </Form>
          )) ||
            (requestEmail && (
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();

                  try {
                    await axios.get(
                      process.env.REACT_APP_API_LOC +
                        "/user/request-password-change?email=" +
                        formData.email
                    );
                    setInfo(
                      "Se te ha enviado el email de restablecimiento. Si no lo encuentras revisa tu carpeta de spam."
                    );
                    setError("");
                    setRequestEmail(false);
                  } catch (err) {
                    setError("Error. Vuelve a intentarlo más tarde.");
                    setInfo("");
                  }
                }}
                className="login-form"
              >
                {error && <Alert variant="danger">{error}</Alert>}
                {info && <Alert variant="info">{info}</Alert>}
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email para reseteo de contraseña</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Tu email"
                    value={formData.email}
                    onChange={handleFormChange}
                    name="email"
                    required
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="nav-button btn-send"
                >
                  Enviar
                </Button>
              </Form>
            )) ||
            (loginData && (
              <div className="d-flex flex-column">
                <QRCode
                  value={
                    process.env.REACT_APP_API_LOC +
                    "/user/get/" +
                    loginData.userUuid
                  }
                  className="qr w-100 h-100"
                  size={400}
                />
                {loginData.isAdmin && (
                  <div className="mb-3">
                    <button
                      onClick={async () => {
                        try {
                          const res = await axios.get(
                            process.env.REACT_APP_API_LOC +
                              "/user/get-short-token",
                            {
                              headers: {
                                Authorization: "Bearer " + loginData.token,
                              },
                            }
                          );
                          setShortToken(res.data.token);
                        } catch (err) {
                          Swal.fire({
                            icon: "error",
                            title: "¡Error!",
                            text:
                              "Algo ha salido mal, no se ha podido obtener tu token: " +
                              err,
                          });
                        }
                      }}
                      className="btn btn-primary nav-button w-50"
                    >
                      Obtener mi token
                    </button>
                    <Link
                      onClick={() => setShowModal(false)}
                      to="/admin/escaner-de-qr"
                      className="btn btn-primary nav-button w-50"
                    >
                      Escaner de QR
                    </Link>
                  </div>
                )}
                {loginData.isAdmin && shortToken && (
                  <span
                    className="short-token mb-3 w-100"
                    onClick={() => {
                      try {
                        navigator.clipboard.writeText(shortToken);
                      } catch (e) {}
                      setShortTokenCopied(true);
                      setTimeout(() => setShortTokenCopied(false), 2000);
                    }}
                  >
                    {shortToken}
                    <FontAwesomeIcon
                      icon={shortTokenCopied ? faThumbsUp : faCopy}
                      style={{ marginLeft: "10px" }}
                    />
                  </span>
                )}
                <button
                  onClick={() => {
                    clearLoginData();
                    window.location.href = "/";
                  }}
                  className="btn btn-primary nav-button"
                >
                  Cerrar sesión
                </button>
              </div>
            )) || (
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();

                  try {
                    const res = await axios.post(
                      process.env.REACT_APP_API_LOC + "/user/login",
                      {
                        email: formData.email,
                        pwd: formData.pwd,
                      }
                    );
                    setLoginData(res.data);
                    setShowModal(false);
                  } catch (err) {
                    setError(
                      "Error de inicio de sesión. Verifica tus credenciales."
                    );
                    setInfo("");
                  }
                }}
                className="login-form"
              >
                {error && <Alert variant="danger">{error}</Alert>}
                {info && <Alert variant="info">{info}</Alert>}
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Tu email"
                    value={formData.email}
                    onChange={handleFormChange}
                    name="email"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword d-flex">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Tu contraseña"
                    value={formData.pwd}
                    name="pwd"
                    onChange={handleFormChange}
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-between">
                  <Button
                    variant="primary"
                    type="submit"
                    className="nav-button btn-send"
                  >
                    Iniciar Sesión
                  </Button>
                  <Button
                    variant="primary"
                    className="nav-button btn-send"
                    onClick={() => setRequestEmail(true)}
                  >
                    He olvidado mi contraseña
                  </Button>
                </div>
              </Form>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
}
