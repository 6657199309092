import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

export default function AnnouncementLinkCard({ title, link, className }) {
  return (
    <article className={"announcement-link-card " + className}>
      <div className="m-2">
        <Card className="crd p-md-3 row">
          <Card.Title className="crd-title p-0 col-8 col-md-12 d-flex justify-content-center">
            {title}
          </Card.Title>
          <Card.Body className="crd-body col-4 col-md-12">
            <Link
              to={link}
              title={title}
              className="text-decoration-none btn btn-primary w-100"
            >
              Ir
            </Link>
          </Card.Body>
        </Card>
      </div>
    </article>
  );
}
