import { useState } from "react";

export default function useWindowSize() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        const isMobileCondition = window.innerWidth <= 767;
        if (isMobileCondition !== isMobile) {
            setIsMobile(isMobileCondition);
        }
    };

    window.addEventListener('resize', handleResize);

    return { isMobile };
}