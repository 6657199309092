import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./routes/AboutUs";
import HomeSection from "./components/HomeSection";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import "./App.css";
import Home from "./routes/Home";
import { LoginDataContext } from "./context/LoginDataProvider";
import { useContext } from "react";
import AnnouncementLinks from "./routes/AnnouncementLinks";
import QrScanner from "./routes/QrScanner";
import NewActivity from "./routes/NewActivity";
import AnnouncementDetail from "./components/AnnouncementDetail";
import HowToBePartner from "./routes/HowToBePartner";
import Announcements from "./routes/Announcements";
import Footer from "./components/Footer";
import AdminTable from "./components/AdminTable";
import NewAnnouncement from "./routes/NewAnnouncement";
import Collaborators from "./routes/Collaborators";
import Contact from "./routes/Contact";
import AddUsers from "./routes/AddUsers";

export default function App() {
  const { loginData } = useContext(LoginDataContext);

  const announcementFields = new Map();
  announcementFields.set("Título", "title");
  announcementFields.set("Tipo", "type");
  announcementFields.set("Público", "isPublic");
  announcementFields.set("Fecha", "date");
  announcementFields.set("id", "id");

  const userFields = new Map();
  userFields.set("Nombre", "name");
  userFields.set("Apellidos", "surnames");
  userFields.set("Email", "email");
  userFields.set("Actividades", "activities");
  userFields.set("Curso", "grade");
  userFields.set("Teléfono", "phone");
  userFields.set("Fecha baja", "endDate");
  userFields.set("Admin", "isAdmin");
  userFields.set("id", "uuid");

  const activityFields = new Map();
  activityFields.set("Nombre", "name");
  activityFields.set("Fecha de inicio", "startDate");
  activityFields.set("Fecha de fin", "endDate");
  activityFields.set("id", "id");

  return (
    <Router>
      <NavBar />
      <main>
        <Routes>
          <Route
            path="/"
            element={
              <Home>
                <Header />
                {loginData && (
                  <HomeSection route="anuncios" header="Anuncios">
                    <AnnouncementLinks />
                  </HomeSection>
                )}
                <HomeSection route="quienes-somos" header="Quiénes somos">
                  <AboutUs />
                </HomeSection>
                <HomeSection route="colaboradores" header="Colaboradores">
                  <Collaborators />
                </HomeSection>
                {!loginData && (
                  <HomeSection route="blog" header="Blog">
                    <Announcements />
                  </HomeSection>
                )}
                <HomeSection
                  route="como-hacerme-socio"
                  header="Cómo hacerme socio"
                >
                  <HowToBePartner />
                </HomeSection>
                <HomeSection route="contacto" header="Contacta con nosotros">
                  <Contact />
                </HomeSection>
              </Home>
            }
          />
          {loginData && (
            <Route
              path="/noticias"
              element={<Announcements header="Noticias" />}
            />
          )}
          <Route path="/noticias/:title" element={<AnnouncementDetail />} />
          {loginData && loginData.isAdmin && (
            <Route path="/admin/escaner-de-qr" element={<QrScanner />} />
          )}
          {loginData && (
            <Route
              path="/actividades"
              element={<Announcements header="Actividades" type="a" />}
            />
          )}
          {loginData && (
            <Route
              path="/actividades/:title"
              element={<AnnouncementDetail />}
            />
          )}
          {loginData && (
            <Route
              path="/concursos-y-sorteos"
              element={<Announcements header="Concursos y Sorteos" type="cs" />}
            />
          )}
          {loginData && (
            <Route
              path="/concursos-y-sorteos/:title"
              element={<AnnouncementDetail />}
            />
          )}
          {loginData && loginData.isAdmin && (
            <Route path="/admin/escaner-de-qr" element={<QrScanner />} />
          )}
          {loginData && loginData.isAdmin && (
            <Route
              path="/admin/actividades"
              element={
                <AdminTable
                  endpoint="/activity/get-all-activities"
                  entity="Actividades"
                  fields={activityFields}
                  deleteEndpoint="/activity/delete-activity"
                />
              }
            />
          )}
          {loginData && loginData.isAdmin && (
            <Route
              path="/admin/actividades/detalle"
              element={<NewActivity />}
            />
          )}
          {loginData && loginData.isAdmin && (
            <Route
              path="/admin/anuncios"
              element={
                <AdminTable
                  endpoint="/announcement/get-announcements"
                  entity="Anuncios"
                  fields={announcementFields}
                  deleteEndpoint="/announcement/delete-announcement"
                />
              }
            />
          )}
          {loginData && loginData.isAdmin && (
            <Route
              path="/admin/anuncios/detalle"
              element={<NewAnnouncement />}
            />
          )}
          {loginData && loginData.isAdmin && (
            <Route
              path="/admin/socios"
              element={
                <AdminTable
                  endpoint="/user/get-list-users"
                  entity="Socios"
                  fields={userFields}
                  deleteEndpoint="/user/delete-user"
                />
              }
            />
          )}
          {loginData && loginData.isAdmin && (
            <Route path="/admin/socios/detalle" element={<AddUsers />} />
          )}
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}
