import "./HomeSection.css";

export default function HomeSection({ className, route, header, children }) {
  return (
    <section
      className={
        (className ? className : "") +
        " full-height d-flex flex-column home-section"
      }
      id={route}
    >
      <div className="h2-container">
        <h2>{header}</h2>
      </div>
      <div className="d-flex justify-content-center align-items-center h-100">
        {children}
      </div>
    </section>
  );
}
