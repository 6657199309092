import React, { createContext, useState } from "react";
import Cookies from "js-cookie";

export const LoginDataContext = createContext();

export const LoginDataProvider = ({ children }) => {
  const [loginData, updateLoginData] = useState(() => {
    return Cookies.get("loginData")
      ? JSON.parse(Cookies.get("loginData"))
      : null;
  });

  const setLoginData = (data) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
    Cookies.set("loginData", JSON.stringify(data).replaceAll(" ", ""), {
      expires,
    });
    updateLoginData(data);
  };

  const clearLoginData = () => {
    Cookies.remove("loginData");
    updateLoginData(null);
  };

  return (
    <LoginDataContext.Provider
      value={{ loginData, setLoginData, clearLoginData }}
    >
      {children}
    </LoginDataContext.Provider>
  );
};
