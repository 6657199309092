import "./HowToBePartner.css";
import { Bounce } from "react-reveal";

export default function HowToBePartner() {
  return (
    <div className="row htbp-container">
      <div className="d-flex col-sm-12 col-lg-6 p-sm-5 benefits">
        <Bounce>
          <img
            src="img/benefits.svg"
            title="¿Qué beneficios tiene ser socio?
            3€ de descuento en el farmaforum, entrada gratuita a 4 jornadas formativas (24-25 de octubre y 19-20 de febrero), acceso a programas de movilidad nacional e internacional, llavero personalizado de socio y sorteos y concursos exclusivos.
            "
            alt="¿Qué beneficios tiene ser socio?
              3€ de descuento en el farmaforum, entrada gratuita a 4 jornadas formativas (24-25 de octubre y 19-20 de febrero), acceso a programas de movilidad nacional e internacional, llavero personalizado de socio y sorteos y concursos exclusivos.
              "
          />
        </Bounce>
      </div>
      <div className="col-sm-12 col-lg-6 text d-flex flex-column how-to">
        <Bounce>
          <div className="background-rect pb-2 pt-4 px-4 w-100">
            <p>
              Para ello solo tienes que rellenar un{" "}
              <a
                href="https://forms.gle/wDtLzexT2c8PY9EA7"
                title="Enlace al formulario de inscripción"
              >
                pequeño formulario
              </a>{" "}
              y pagar la cuota de inscripción (5€).
            </p>
            <p>
              La cuota de inscripción se puede pagar por transferencia bancaria
              o en efectivo como se indica en el propio formulario.
            </p>
            <p>
              Una vez que formas parte de la asociación puedes entrar en nuestro
              grupo de WhatsApp para hacer más fácil la comunicación de todas
              las actividades y beneficios.
            </p>
            <p>
              Si tienes cualquier duda te puedes poner en contacto con nosotros
              a través de{" "}
              <a
                href="https://www.instagram.com/asefsalamanca"
                title="Enlace al instagram de la asociación"
              >
                @asefsalamanca
              </a>{" "}
              o en el correo{" "}
              <a
                href="mailto:asef@usal.es"
                title="Enlace para envío de email al correo de la asociación"
              >
                asef@usal.es
              </a>
              .
            </p>
          </div>
        </Bounce>
      </div>
    </div>
  );
}
