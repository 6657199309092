import { Button, Table } from "react-bootstrap";
import useContentFromBackend from "../hooks/useContentFromBackend";
import { useContext, useState } from "react";
import { LoginDataContext } from "../context/LoginDataProvider";
import Pagination from "./Pagination";
import "./AdminTable.css";
import Swal from "sweetalert2";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default function AdminTable({
  endpoint,
  entity,
  fields,
  deleteEndpoint,
}) {
  const [page, setPage] = useState(1);
  const [deleted, setDeleted] = useState(false);
  const { loginData } = useContext(LoginDataContext);
  const { content, isLastPage } = useContentFromBackend(
    endpoint + "?page=" + page,
    "get",
    null,
    null,
    loginData.token,
    true,
    deleted
  );

  const headers = [...fields.keys()];

  return (
    <div className="admin-table">
      <h1>{entity}</h1>
      <div className="d-flex w-100 justify-content-between">
        <Button
          className="nav-button btn btn-primary mb-2"
          variant="primary"
          onClick={() => {
            axios
              .get(process.env.REACT_APP_API_LOC + endpoint + "-excel", {
                responseType: "blob",
                headers: { Authorization: "Bearer " + loginData.token },
              })
              .then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", entity + ".csv");
                document.body.appendChild(link);
                link.click();
              });
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
        </Button>
        <a
          style={{ width: "15%" }}
          className="nav-button btn btn-primary p-3 mb-2"
          href={(window.location.href.endsWith("/")
            ? window.location.href.substring(0, window.location.href.length - 1)
            : window.location.href
          ).concat("/detalle")}
        >
          Añadir
        </a>
      </div>
      <Pagination
        isLastPage={isLastPage}
        onPageChange={(page) => setPage(page)}
        className="justify-content-center w-100 mb-5"
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              {headers.map((header, index) =>
                header === "id" ? (
                  <>
                    <th key={index}></th>
                    <th key={index + 1}></th>
                  </>
                ) : (
                  <th key={index}>{header}</th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {content.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, colIndex) =>
                  header === "id" ? (
                    <>
                      <td>
                        <a
                          href={(window.location.href.endsWith("/")
                            ? window.location.href.substring(
                                0,
                                window.location.href.length - 1
                              )
                            : window.location.href
                          ).concat("/detalle?id=" + row[fields.get(header)])}
                          className="btn btn-primary nav-button"
                        >
                          Editar
                        </a>
                      </td>
                      <td>
                        <Button
                          className="nav-button"
                          onClick={async () => {
                            try {
                              const config = {
                                method: "DELETE",
                                url:
                                  process.env.REACT_APP_API_LOC +
                                  deleteEndpoint +
                                  "/" +
                                  row[fields.get(header)],
                                headers: {
                                  Authorization: "Bearer " + loginData.token,
                                },
                              };
                              await axios(config);
                              setDeleted(!deleted);
                            } catch (err) {
                              Swal.fire({
                                icon: "error",
                                title: "¡Error!",
                                text: "Algo ha salido mal, es posible que el elemento no se haya eliminado correctamente",
                              });
                            }
                          }}
                        >
                          Eliminar
                        </Button>
                      </td>
                    </>
                  ) : (
                    <td key={colIndex}>
                      {transformDataType(row[fields.get(header)])}
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Pagination>
    </div>
  );
}

function transformDataType(data) {
  if (typeof data == "boolean") {
    return data ? "Sí" : "No";
  }
  if (Array.isArray(data)) {
    return data.join(", ");
  }
  return data;
}
