export default function Footer() {
  return (
    <footer className="text-light p-4 d-flex flex-row justify-content-between">
      <div>
        <p>Información de contacto</p>
        <a href="mailto:asef@usal.es">asef@usal.es</a>
      </div>
      <div>
        <p>ASEF</p>
        <p>
          <a href="https://www.youtube.com/watch?v=FGiV3vc-aSo" target="blank">
            Un video que te cambiará la vida
          </a>
        </p>
      </div>
    </footer>
  );
}
