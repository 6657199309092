import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export default function useContentFromBackend(endpoint, method, contentField, body, token, isPaginated, rerenderFlag) {

    const [content, setContent] = useState([]);
    const [isLastPage, setIsLastPage] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        const auth = token ? "Bearer " + token : null;
        const apiCall = async() => {
            try {
                const config = {
                    method,
                    url: process.env.REACT_APP_API_LOC +
                        endpoint,
                    data: body,
                    headers: {}
                }

                if (auth) {
                    config.headers.Authorization = auth;
                }

                const res = await axios(config);
                const data = isPaginated ? res.data.itemList : res.data;
                setIsLastPage(res.data.isLastPage);
                if (Array.isArray(data)) {
                    if (contentField) {
                        setContent(data.map(
                            (data) => [data['id'], data[contentField]]
                        ));
                    } else {
                        setContent(data);
                    }
                } else {

                    setContent(data[contentField]);
                }
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: '¡Error!',
                    text: 'Algo ha salido mal, es posible que los componentes de la página no se muestren correctamente. Si el error persiste cierra sesión y vuelve a iniciarla o contacta con nosotros.',
                });
                setError(err);
            }
        };
        apiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpoint, method, contentField, token, isPaginated, rerenderFlag]);

    return { content, isLastPage, error };
}