import { Form } from "react-bootstrap";
import { handleFormChange } from "../hooks/useFormData";
import React from "react";

export default function CustomFormGroup({
  label,
  formData,
  type,
  name,
  required,
  min,
  controlOverride,
  checkable,
  className,
}) {
  const sustitute = controlOverride
    ? React.cloneElement(controlOverride, {
        value: formData[name],
        checked: checkable ? formData[name] : null,
        onChange: handleFormChange,
      })
    : null;

  return (
    <Form.Group controlId={name} className={className}>
      <Form.Label>{label}</Form.Label>
      {sustitute ? (
        sustitute
      ) : (
        <Form.Control
          type={type}
          name={name}
          value={formData[name]}
          onChange={handleFormChange}
          min={min}
          required={required}
        />
      )}
    </Form.Group>
  );
}
