import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import "./ScrollToTopButton.css";

export default function ScrollToTopButton() {
  return (
    <Button
      variant="primary"
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}
      className={" btn btn-primary scroll-to-top-button d-none"}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </Button>
  );
}
