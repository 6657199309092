import "./Collaborators.css";

export default function Collaborators() {
  const collaborators = [
    [
      <img
        src="/img/feef.webp"
        alt="Logo de la FEEF"
        title="Logo de la FEEF"
      />,
      <a
        target="blank"
        href="https://www.feef.es/"
        title="Enlace a la web de la FEEF"
      >
        Federación Española de Estudiantes de Farmacia | FEEF
      </a>,
    ],
    [
      <img
        src="/img/usal.webp"
        alt="Logo de la USAL"
        title="Logo de la USAL"
      />,
      <a
        target="blank"
        href="https://www.usal.es/"
        title="Enlace a la web de la USAL"
      >
        Universidad de Salamanca | USAL
      </a>,
    ],
    [
      <img
        src="/img/facultad.webp"
        alt="Logo de la Facultad de Farmacia"
        title="Logo de la Facultad de Farmacia"
      />,
      <a
        target="blank"
        href="https://www.usal.es/facultad-de-farmacia"
        title="Enlace a la web de la Facultad de Farmacia"
      >
        Facultad de Farmacia
      </a>,
    ],
    [
      <img
        src="/img/delegacion.webp"
        alt="Logo de la Delegacion de Estudiantes de Farmacia"
        title="Logo de la Delegacion de Estudiantes de Farmacia"
      />,
      <a
        target="blank"
        href="https://facultadfarmacia.usal.es/"
        title="Enlace a la web de la Delegacion de Estudiantes de Farmacia"
      >
        Delegacion de Estudiantes de Farmacia
      </a>,
    ],
    [
      <img
        src="/img/colegio.webp"
        alt="Logo del Colegio Oficial de Farmacéuticos"
        title="Logo del Colegio Oficial de Farmacéuticos"
      />,
      <a
        target="blank"
        href="https://cofsalamanca.com/"
        title="Enlace a la web del Colegio Oficial de Farmacéuticos"
      >
        Colegio Oficial de Farmacéuticos
      </a>,
    ],
    [
      <img
        src="/img/IPSF.webp"
        alt="Logo de la IPSF"
        title="Logo de la IPSF"
      />,
      <a
        target="blank"
        href="https://www.ipsf.org/"
        title="Enlace a la web de la IPSF"
      >
        International Pharmaceutical Students Federation | IPSF
      </a>,
    ],
  ];
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="row mt-2 mt-md-5 p-3 p-md-5 collaborators w-100">
        {collaborators.map((collaborator, index) => (
          <div
            key={index}
            className="collaborator col-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-center py-1"
          >
            <div className="image-container d-flex justify-content-left align-items-center">
              {collaborator[0]}
            </div>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              {collaborator[1]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
