import "./Header.css";

export default function Header() {
  return (
    <header
      className={
        "full-height d-flex flex-column align-items-center pb-3 justify-content-center"
      }
    >
      <h1 className="text-center">
        ASOCIACIÓN SALMANTINA DE ESTUDIANTES DE FARMACIA
      </h1>
      <div className="row justify-content-center align-items-center col-12 motto-container background-rect d-flex justify-content-center align-items-center align-self-bottom">
        <div className="col-sm-12 col-md-1 text-center motto-img">
          <img
            src="img/logo.svg"
            title="Logo de la ASEF"
            alt="Logo de la ASEF"
            className="img-fluid"
          />
        </div>
        <div className="col-sm-12 col-md-5 py-3 text-center motto-txt">
          <p>
            Estudiantes <b>activos</b>
          </p>
          <p>
            y con <b>principios,</b>
          </p>
          <p>
            nuestra <b>fórmula</b>
          </p>
        </div>
      </div>
    </header>
  );
}
