import { useContext, useRef, useState } from "react";
import QrReader from "modern-react-qr-reader";
import useContentFromBackend from "../hooks/useContentFromBackend";
import { LoginDataContext } from "../context/LoginDataProvider";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import "./QrScanner.css";
import { useEffect } from "react";
import Swal from "sweetalert2";

export default function QrScanner() {
  const { loginData } = useContext(LoginDataContext);
  const { content } = useContentFromBackend(
    "/activity/get-active-activities",
    "get",
    "name",
    null,
    loginData.token
  );
  const [selectedActivity, setSelectedActivity] = useState(content[0]);
  const [videoInputDevices, setVideoInputDevices] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setVideoInputDevices(videoInputDevices);
    });
  }, []);

  useEffect(() => {
    setSelectedActivity(content[0]);
  }, [content]);

  return content.length > 0 &&
    videoInputDevices &&
    videoInputDevices[0].deviceId ? (
    <>
      <div
        className={
          "scanned w-100 h-100 justify-content-center align-items-center " +
          (scanned ? "d-flex" : "d-none")
        }
      ></div>
      <div className="activity-dropdowns d-flex flex-row navbar-brand">
        <Dropdown className="nav-dropdown">
          <Dropdown.Toggle variant="none">
            {selectedActivity ? selectedActivity[1] : content[0][1]}
          </Dropdown.Toggle>
          <Dropdown.Menu className="bg-transparent">
            {content.map((activity) => (
              <Dropdown.Item
                onClick={() => setSelectedActivity(activity)}
                key={activity[0]}
              >
                {activity[1]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="nav-dropdown">
          <Dropdown.Toggle variant="none">
            {(selectedDevice ? selectedDevice : videoInputDevices[0]).label}
          </Dropdown.Toggle>
          <Dropdown.Menu className="bg-transparent">
            {videoInputDevices.map((device) => (
              <Dropdown.Item
                onClick={() => setSelectedDevice(device)}
                key={device.deviceId}
              >
                {device.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <QrReader
        className="h-75"
        delay={300}
        deviceId={selectedDevice}
        onError={(error) => error}
        onScan={async (data, error) => {
          if (!scanned && data) {
            const validUser = await axios.get(data);
            if (validUser.status === 200) {
              try {
                await axios.get(data + "/add-activity/" + selectedActivity[0], {
                  headers: { Authorization: "Bearer " + loginData.token },
                });
              } catch (e) {
                console.log(e);
                Swal.fire({
                  icon: "error",
                  title: "¡Error!",
                  text: "No se ha encontrado a este usuario en el registro",
                });
              }
              setScanned(true);
              setTimeout(() => setScanned(false), 1500);
            }
          }
        }}
      />
    </>
  ) : (
    <p style={{ marginTop: 100 }}>
      No hay actividades aún o tu dispositivo no puede escanear QR
    </p>
  );
}
